export class ProductTypeModel {
  productTypeId: number = 0;
  entityId: number = 0;
  //entityChildId: number = 0;
  productCategoryIconId: number = 0;
  categoryName: string = '';
  categoryDisplayName: string = '';
  vatPercent?: number = 0;
  isActive: boolean = true;
}
